import React from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import "../styles/App.css";
import Home from "./Home";
import Order from "./order/Order";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Login from "./auth/Login";

import {PrivateRoute, PublicRoute} from "../routes/ProtectedRoutes";
import {UserProvider} from "../contexts/UserProvider";
import {SnackbarProvider} from "../contexts/SnackbarProvider";
import DashboardLayout from "./dashboard/DashboardLayout";

function pxToRem(value) {
	return `${value / 16}rem`;
}

function responsiveFontSizes({sm, md, lg}) {
	return {
		"@media (min-width:600px)": {
			fontSize: pxToRem(sm),
		},
		"@media (min-width:900px)": {
			fontSize: pxToRem(md),
		},
		"@media (min-width:1200px)": {
			fontSize: pxToRem(lg),
		},
	};
}

const FONT_PRIMARY = "Public Sans, sans-serif";

const theme = createTheme({
	root: {
		"--primary": "#515FC2",
		"--secondary": "#F2F2F2",
	},
	palette: {
		primary: {
			darker: "#101327",
			main: "#515FC2",
			lighter: "#dcdff3",
		},
		secondary: {
			main: "#F2F2F2",
		},
		error: {
			lighter: "#fdd9d7",
			main: "#f44336",
			darker: "#310d0b",
		},
		warning: {
			lighter: "#ffedd4",
			main: "#ffa726",
			darker: "#332108",
		},
		info: {
			lighter: "#d4f0fd",
			main: "#29b6f6",
			darker: "#082431",
		},
		success: {
			lighter: "#e0f1e1",
			main: "#66bb6a",
			darker: "#142515",
		},
		background: {
			paper: "#fff",
			default: "#F9FAFB",
			neutral: "#F4F6F8",
		},
	},
	typography: {
		fontFamily: FONT_PRIMARY,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		fontWeightBold: 700,
		h1: {
			fontWeight: 800,
			lineHeight: 80 / 64,
			fontSize: pxToRem(40),
			...responsiveFontSizes({sm: 52, md: 58, lg: 64}),
		},
		h2: {
			fontWeight: 800,
			lineHeight: 64 / 48,
			fontSize: pxToRem(32),
			...responsiveFontSizes({sm: 40, md: 44, lg: 48}),
		},
		h3: {
			fontWeight: 700,
			lineHeight: 1.5,
			fontSize: pxToRem(24),
			...responsiveFontSizes({sm: 26, md: 30, lg: 32}),
		},
		h4: {
			fontWeight: 700,
			lineHeight: 1.5,
			fontSize: pxToRem(20),
			...responsiveFontSizes({sm: 20, md: 24, lg: 24}),
		},
		h5: {
			fontWeight: 700,
			lineHeight: 1.5,
			fontSize: pxToRem(18),
			...responsiveFontSizes({sm: 19, md: 20, lg: 20}),
		},
		h6: {
			fontWeight: 700,
			lineHeight: 28 / 18,
			fontSize: pxToRem(17),
			...responsiveFontSizes({sm: 18, md: 18, lg: 18}),
		},
		subtitle1: {
			fontWeight: 600,
			lineHeight: 1.5,
			fontSize: pxToRem(16),
		},
		subtitle2: {
			fontWeight: 600,
			lineHeight: 22 / 14,
			fontSize: pxToRem(14),
		},
		body1: {
			lineHeight: 1.3,
			fontSize: pxToRem(16),
		},
		body2: {
			lineHeight: 22 / 14,
			fontSize: pxToRem(14),
		},
		caption: {
			lineHeight: 1.5,
			fontSize: pxToRem(12),
		},
		overline: {
			fontWeight: 700,
			lineHeight: 1.5,
			fontSize: pxToRem(12),
			textTransform: "uppercase",
		},
		button: {
			fontWeight: 700,
			lineHeight: 24 / 14,
			fontSize: pxToRem(14),
			// textTransform: "capitalize",
		},
	},
});

theme.components = {
	MuiTableCell: {
		styleOverrides: {
			head: {
				color: theme.palette.text.secondary,
				backgroundColor: theme.palette.background.neutral,
			},
		},
	},
};

function App() {
	const location = useLocation();

	const isDashboard = location.pathname.startsWith("/diaxirisi");
	const isOrder = location.pathname == "/diaxirisi/paraggelies/prosthiki";

	const content = (
		<Routes>
			<Route
				path="/sindesi"
				element={
					<PublicRoute>
						<Login />
					</PublicRoute>
				}
			/>
			<Route
				path="/diaxirisi/*"
				element={
					<PrivateRoute>
						<DashboardLayout />
					</PrivateRoute>
				}
			/>
			<Route path="*" element={<Order />} />
		</Routes>
	);
	return (
		<SnackbarProvider>
			<Helmet>
				<title>ΛΑΦ Ξάνθης</title>
			</Helmet>
			<UserProvider>
				<ThemeProvider theme={theme}>
					{isDashboard ? (
						isOrder ? (
							<Container maxWidth={"lg"} className="App">
								{content}
							</Container>
						) : (
							content
						)
					) : (
						<Container maxWidth={"lg"} className="App">
							{content}
						</Container>
					)}
				</ThemeProvider>
			</UserProvider>
		</SnackbarProvider>
	);
}

export default App;
