import {InputLabel, ListSubheader, MenuItem, Select} from "@mui/material";
import React from "react";
import {useFormContext, Controller} from "react-hook-form";

function IdiotitaField({disabled}) {
	const {
		control,
		formState: {errors},
	} = useFormContext();
	const error = errors.idiotita;

	return (
		<>
			<InputLabel
				id="idiotita-select-label"
				style={{marginTop: 20}}
				required
			>
				Ιδιότητα
			</InputLabel>
			<Controller
				name="idiotita"
				control={control}
				defaultValue="" // or some default
				rules={{required: true}}
				render={({field}) => (
					<Select
						labelId="idiotita-select-label"
						variant="standard"
						error={!!error}
						fullWidth
						required
						disabled={disabled}
						{...field} // spread the Controller props
					>
						<ListSubheader>Ιδιότητα</ListSubheader>
						<MenuItem value="stel_sx_en">
							Στέλεχος ΣΞ εν ενεργεία
						</MenuItem>
						<MenuItem value="stel_sx_ap">
							Στέλεχος ΣΞ εν αποστρατεία
						</MenuItem>
						<MenuItem value="stel_pa_pn">Στέλεχος ΠΑ - ΠΝ</MenuItem>
						<MenuItem value="siz_gon_tek_str">
							Σύζυγος - Γονέας - Τέκνο στρκού
						</MenuItem>
						<MenuItem value="ef_ax">Έφεδρος Αξκός</MenuItem>
						<MenuItem value="ethn">Εθνοφύλακας</MenuItem>
						<MenuItem value="ekp">Εκπαιδευτικός</MenuItem>
						<MenuItem value="anap_ekp">
							Αναπληρωτής Εκπαιδευτικός
						</MenuItem>
						<MenuItem value="elas">
							Στέλεχος ΕΛ.ΑΣ (αστυνομία)
						</MenuItem>
						<MenuItem value="pir_lim">
							Στέλεχος Πυροσβεστικής - Λιμενικού
						</MenuItem>
						<ListSubheader>Μόνιμος Υπάλληλος</ListSubheader>
						<MenuItem value="ypetha">Υπάλληλος ΥΠΕΘΑ</MenuItem>
						<MenuItem value="dikas">Δικαστικός Υπάλληλος</MenuItem>
						<MenuItem value="tel">Τελωνειακός Υπάλληλος</MenuItem>
						<MenuItem value="elegk_syn">
							Υπάλληλος Ελεγκτικού Συνεδρίου
						</MenuItem>
						<ListSubheader>Φοιτητής</ListSubheader>
						<MenuItem value="tek_str">Τέκνο Στρκού</MenuItem>
						<MenuItem value="tek_ap_str">
							Τέκνο Απόστρατου Στρκού
						</MenuItem>
						<MenuItem value="tek_ethn">Τέκνο Εθνοφύλακα</MenuItem>
						<MenuItem value="tek_ef_ax">
							Τέκνο Έφεδρου Αξκού
						</MenuItem>
						<MenuItem value="tek_as">Τέκνο Αστυνομικού</MenuItem>
					</Select>
				)}
			/>
		</>
	);
}

export default IdiotitaField;
